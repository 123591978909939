import React from 'react';
import './ThomasHeader.css'; // Import the CSS file for styling
import Thomasimage from '../images/IMG_6775-6.jpg'; // Adjust the path to your image

function ThomasHeader() {
  return (
    <div className="app-container">
      <div className="text-section">
        <h1>Thomas Dye</h1>
        <h2>Lighting Designer</h2>
        <h3>Event Manager</h3>
        <h4>Software Tech</h4>
        <a href="mailto:ContactThomas@thomasdye.net">ContactThomas@thomasdye.net</a>
        <br />
        <a href="https://www.instagram.com/thomas_dye/" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
      <div className="image-section">
        <img src={Thomasimage} alt="Cereal" className="cereal-image" />
      </div>
    </div>
  );
}

export default ThomasHeader;
