import logo from './logo.svg';
import './App.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Header from './Headers/header.js'; // Import the Header component
import ThomasHeader from './ThomasHeader/ThomasHeader.js'; // Import the ThomasHeader component
import Copyright from  './Copyright/Copyright.js'; // Import the Copyright component

const TRACKING_ID = "G-93BKQVJT1E"; // Replace with your tracking ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div>
          <Header />

    <div>
  
      <ThomasHeader />
      <Copyright />
    </div>
    </div>
  );
}

export default App;
