import React from 'react';
import './Copyright.css'; // Import the CSS file for styling

function Copyright() {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className="footer">
      <p>&copy; {currentYear} Thomasdye.net</p>
    </footer>
  );
}

export default Copyright;
