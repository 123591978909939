import React from 'react';
import './Header.css'; // Import the CSS file

function Header() {
  return (
    <div>
      <div className="header">
        <h1>Thomas Dye</h1> {/* Your name */}
      </div>
      {/* <nav>
        <ul>
          <li><a href="#about">About</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav> */}
    </div>
  );
}

export default Header;
